<script>

import SelectFunctions from '../select/SelectFunctions';
import ErrorMessages from '../components/ErrorMessages';
import UsergroupsAddEdit from '../views/Usergroup_AddEdit';
// import UsergroupsNotifications from '../views/Usergroup_Notifications';

import _ from 'lodash';

export default
  {
    components: { ErrorMessages, UsergroupsAddEdit, /*UsergroupsNotifications*/ },

    created ()  
    {


    },

    mounted ()
    {
      console.log('CompaniesManagement Component Mounted');
      this.getUserGroupList();

    },

    methods:
    {
      async getUserGroupList ()
      {
        this.processing = true;

        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + 'usergroups/',
          { credentials: 'include' }
        );
        if (response.ok)
        {
          this.usergroupListInternal = await response.json();
          console.log("getUserGroupList : usergroups : ", this.usergroupListInternal);
        }
        else
        {
          this.errorList.push('There was a problem retrieving the sites list from the server.')
        }
        this.processing = false;

      },

      newItem ()
      {
        this.itemToEdit = {},
          this.showEditDialog = true;
        _.defer(() =>
        {
          this.showEditDialog = false;
        });

      },

      editItem (item)
      {
        this.itemToEdit = _.clone(item);
        this.showEditDialog = true;
        _.defer(() =>
        {
          this.showEditDialog = false;
        });

      },
      editNotifications (item)
      {
        this.itemToEdit = _.clone(item);
        this.showNotificationDialog = true;
        _.defer(() =>
        {
          this.showNotificationDialog = false;
        });

      },

      async deleteItem (item)
      {

        this.itemToDelete = item;

        let res = await this.$root.$confirm(
          'Are you sure that you wish to delete this user group?',
          {
            title: 'Delete User Group?',
            color: 'red'
          }
        );

        if (res)
        {
          let response = await SelectFunctions.fetch(
            process.env.VUE_APP_BACKEND_ENDPOINT + 'usergroups/',
            {
              method: 'DELETE',
              credentials: 'include',
              headers:
              {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(this.item)
            }
          );
          this.getUserGroupList();
        }


      },

      handleRecordInsert ()
      {
        this.getUserGroupList();
      },
      handleRecordCancel ()
      {
        this.getUserGroupList();
      },

    },

    computed:
    {
      UsergroupList ()
      {
        return this.usergroupListInternal;
      }

    },

    data: () =>
    {
      return {
        breadcrumbs: [
          {
            text: 'Home',
            icon: 'mdi-home',
            link: '/'
          },
          {
            text: 'Usergroup Management',
            icon: '',
            link: '#',
          }
        ],
        usergroupListInternal: [],
        itemToEdit: {},
        itemToDelete: {},
        showEditDialog: false,
        showNotificationDialog: false,
        showConfirmDelete: false,
        deleteUserGroupConfirm: true,

        errorList: [],
        processing: false,

        selected: [],
        search: '',

        headers: [
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: '',
            value: 'recordActions',
            sortable: false
          },
        ],
      }
    }
  }

</script>

<template>
  <v-slide-y-transition mode="out-in">
    <div>
      <v-container fluid>
        <v-card>
          <v-card-text>
            <v-layout
              row
              wrap
            >
              <v-flex md6>
                <v-breadcrumbs divider=">">
                  <v-breadcrumbs-item
                    v-for="item in breadcrumbs"
                    :key="item.text"
                    :to="item.link"
                    exact
                  >
                    <v-icon v-if="item.icon">{{item.icon}}&nbsp;</v-icon>
                    {{item.text}}
                  </v-breadcrumbs-item>
                </v-breadcrumbs>
              </v-flex>
              <v-flex md6>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  placeholder="Search"
                  prepend-icon="mdi-magnify"
                  clearable
                />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-card style="margin-top:20px;">
          <v-card-text>
            <v-layout
              row
              wrap
            >
              <v-flex
                sm12
                md12
              >
                <v-btn
                  color="accent"
                  dark
                  fab
                  xtop
                  middle
                  right
                  @click="newItem()"
                  absolute
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <span class="headline">Usergroup Management</span>
                <p>
                  This form allows you to add / edit and remove usergroups in the system.
                  <br />
                </p>
                <v-spacer></v-spacer>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-card style="margin-top:20px;">
          <v-card-text>
            <error-messages :errorList="errorList"></error-messages>

            <v-layout
              row
              v-show="processing"
            >
              <v-flex sm12>
                <v-progress-linear
                  v-if="processing"
                  :indeterminate="true"
                ></v-progress-linear>
              </v-flex>
            </v-layout>

            <!-- DATA TABLE FOR USERGROUP LIST -->
            <v-data-table
              :headers="headers"
              :items="UsergroupList"
              class="elevation-0"
              :loading="'Loading'"
              :search="search"
              v-model="selected"
            >

              <template v-slot:item.recordActions="{item}">
                <v-btn
                  class="primary"
                  @click="editItem(item);"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>&nbsp;
                <v-btn
                  class="red"
                  dark
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>&nbsp;

              </template>

              <!-- <template slot="items" slot-scope="props">
                <td>{{ props.item.name }}</td>
                <td>
                  <v-btn color="secondary" class="mx-0" @click="editItem(props.item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn color="secondary" class="mx-0" @click="editNotifications(props.item)">
                    <v-icon>perm_device_information</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn color="red" dark class="mx-0" @click="deleteItem(props.item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </template> -->
              <template slot="no-data">
                <v-alert
                  :value="true"
                  color="info"
                  icon="warning"
                >No Usergroups Located</v-alert>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-container>
      <usergroups-add-edit
        :editItem="itemToEdit"
        :showDialog="showEditDialog"
        @newRecordInserted="handleRecordInsert()"
        @newRecordCancelled="handleRecordCancel()"
      ></usergroups-add-edit>
      <!-- <usergroups-notifications
        :editItem="itemToEdit"
        :showDialog="showNotificationDialog"
        @newRecordInserted="handleRecordInsert()"
        @newRecordCancelled="handleRecordCancel()"
      ></usergroups-notifications> -->

    </div>
  </v-slide-y-transition>
</template>