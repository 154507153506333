<script>

// import _ from 'underscore';
import SelectFunctions from '../select/SelectFunctions';
import ErrorMessages from '../components/ErrorMessages';
export default
  {

    components: { ErrorMessages },

    props: ['editItem', 'showDialog'],
    mounted ()
    {

      this.getMenus();
      this.getFunctions();

    },

    methods:
    {


      async getMenus ()
      {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + 'adminmenus/list/usergroupsform/',
          { credentials: 'include' }
        );
        if (response.ok)
        {
          this.availableMenus = await response.json();
        }
        else
        {
          this.errorList.push('There was a problem retrieving the menus list from the server.')
        }

      },
      async getFunctions ()
      {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + 'usergroups/functions',
          { credentials: 'include' }
        );
        if (response.ok)
        {
          this.availableFunctions = await response.json();
        }
        else
        {
          this.errorList.push('There was a problem retrieving the functions list from the server.')
        }

      },
      async saveForm ()
      {

        if (this.$refs.formUserGroup.validate())
        {

          this.$refs.formUserGroup.resetValidation();
          if (!this.editItemData._id)
          {

            // are in add mode
            let response = await SelectFunctions.fetch(
              process.env.VUE_APP_BACKEND_ENDPOINT + 'usergroups/',
              {
                credentials: 'include',
                method: 'PUT',
                headers:
                {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.editItemData)
              }
            );
            if (response.ok)
            {
              this.$emit('newRecordInserted');
              this.errorList = [];
              this.dialog = false;
            }
            else
            {
              this.errorList.push(
                'There was a problem saving the new record, please re-try'
              );
            }

          }
          else
          {


            // we are in edit mode
            let response = await SelectFunctions.fetch(
              process.env.VUE_APP_BACKEND_ENDPOINT + 'usergroups/',
              {
                credentials: 'include',
                method: 'PATCH',
                headers:
                {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.editItemData)
              }

            );
            if (response.ok)
            {
              this.$emit('newRecordInserted');
              this.errorList = [];
              this.dialog = false;
            }
            else
            {
              this.errorList.push(
                'There was a problem saving the new record, please re-try'
              );
            }
          }

        }

      },
      cancelForm ()
      {
        this.dialog = false;
        this.$emit('newRecordCancelled');

      },


    },

    watch:
    {
      showDialog (newValue, oldValue)
      {
        if (this.showDialog)
        {
          this.editItemData = this.editItem;
          this.errorList = [];
          this.dialog = true;
        }
      }
    },

    data ()
    {
      return {

        availableMenus: [],
        availableFunctions: [],

        selectedMenus: [],

        dialog: false,
        editItemData: {},
        errorList: [],

        activeTab: null,
        menusSearch: '',
        functionsSearch: '',
      }
    },

  }
</script>

<template>
  <v-dialog-transition>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        scrollable
      >
        <v-card min-height="800">
          <v-card-title
            class="headline grey xlighten-2 white--text"
            primary-title
            v-if="this.editItemData._id"
          >
            <v-btn
              text
              dark
              @click.native="cancelForm()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>Edit Usergroup
            <v-spacer></v-spacer>
            <v-btn
              text
              dark
              @click.native="saveForm()"
            >Save</v-btn>
          </v-card-title>

          <v-card-title
            v-else
            class="headline grey xlighten-2 white--text"
            primary-title
          >
            <v-btn
              text
              dark
              @click.native="cancelForm()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>New Usergroup
            <v-spacer></v-spacer>
            <v-btn
              text
              dark
              @click.native="saveForm()"
            >Save</v-btn>
          </v-card-title>

          <v-card-text>
            <v-layout wrap>
              <error-messages :errorList="errorList"></error-messages>
            </v-layout>
            <v-form ref="formUserGroup">
              <v-layout wrap>
                <v-flex
                  ms12
                  md12
                >
                  <v-flex
                    sm12
                    md12
                  >
                    <v-text-field
                      label="Usergroup Name"
                      placeholder="Usergroup Name"
                      v-model="editItemData.name"
                      :rules="[v => !!v || 'Please enter a name for the usergroup']"
                      :readonly="!!this.editItemData._id"
                    />
                  </v-flex>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-tabs
                  background-color="primary"
                  icons-and-text
                  v-model="activeTab"
                >
                  <v-tab
                    href="#tab-menus"
                    class="white--text"
                  >
                    Menu Items
                    <v-icon>mdi-format-list-checkbox</v-icon>
                  </v-tab>

                  <v-tab
                    href="#tab-functions"
                    class="white--text"
                  >
                    Function Permissions
                    <v-icon color="white">mdi-apps-box</v-icon>
                  </v-tab>

                  <v-tab-item value="tab-menus">
                    <v-layout wrap>
                      <v-col
                        cols="12"
                        xs="12"
                      >
                        <v-text-field
                          placeholder="Search"
                          label="Search"
                          prepend-icon="mdi-magnify"
                          v-model="menusSearch"
                        />
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col
                        cols="12"
                        xs="12"
                      >
                        <v-data-table
                          v-if="editItemData"
                          :headers="[
                        {text : 'Menu Name', value : 'name'},
                        {text : 'Description', value : 'comment'},
                      ]"
                          :items="availableMenus"
                          disable-pagination
                          hide-default-footer
                          show-select
                          item-key="_id"
                          v-model="editItemData.menuPermissions"
                          :search="menusSearch"
                        ></v-data-table>
                      </v-col>
                    </v-layout>
                  </v-tab-item>

                  <v-tab-item value="tab-functions">
                    <v-layout wrap>
                      <v-col
                        cols="12"
                        xs="12"
                      >
                        <v-text-field
                          placeholder="Search"
                          label="search"
                          prepend-icon="mdi-magnify"
                          v-model="functionsSearch"
                        />
                      </v-col>
                    </v-layout>

                    <v-layout wrap>
                      <v-col
                        cols="12"
                        xs="12"
                      >
                        <v-data-table
                          :headers="[
												{text : 'Category', value : 'category'},
												{text : 'Code' , value : 'code'},
												{text : 'Name', value : 'name'}
                      ]"
                          :items="availableFunctions"
                          disable-pagination
                          hide-default-footer
                          show-select
                          item-key="_id"
                          v-model="editItemData.functionPermissions"
                          :search="functionsSearch"
                        ></v-data-table>
                      </v-col>
                    </v-layout>
                  </v-tab-item>
                </v-tabs>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-dialog-transition>
</template>
                             
        